import React, { useState, useEffect, useMemo } from 'react';
import './assets/internalApp.css'
import PageTile from './pageTile'

function InternalApp(props) {

	const [developerRole, setDeveloperRole] = useState(false)
	const [costUserRole, setCostUserRole] = useState(false)

	useEffect(() => {
		var db_role = localStorage.getItem('db_portal_user_role')
		if (db_role == 'developer') {
			setDeveloperRole(true)
		}
		else if (db_role == 'cost_user') {
			setCostUserRole(true)
		}
	}, [])

	return (
			<div className="App-DB-internal-app-container">
				{!developerRole && !costUserRole && <PageTile title="DI" name="Database Inventory" handleTabSelection={props.handleTabSelection}/> }
				{!developerRole && <PageTile title="ER" name="Executive Dashboard" handleTabSelection={props.handleTabSelection}/> }
				{!developerRole && !costUserRole && <PageTile title="DA" name="Database Activities" handleTabSelection={props.handleTabSelection}/> }
				{!developerRole && !costUserRole && <PageTile title="CR" name="Cost Savings Report" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="CS" name="Database Cost Summary" handleTabSelection={props.handleTabSelection} /> }
				{!costUserRole && <PageTile title="SQ" name="Slow Query Report" handleTabSelection={props.handleTabSelection} /> }
				{!costUserRole && <PageTile title="AL" name="Audit Log" handleTabSelection={props.handleTabSelection} /> }
				{!costUserRole && <PageTile title="ASQ" name="AppName Slow Query" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="AC" name="Atlas Costs" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="COR" name="Cost Reports" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && !costUserRole && <PageTile title="DT" name="Database Tasks" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && !costUserRole && <PageTile title="DTA" name="Database Tasks Assignee View" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && !costUserRole && <PageTile title="RDB" name="Release Databases" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && !costUserRole && <PageTile title="RJS" name="Release Jira Status" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && !costUserRole && <PageTile title="RSM" name="Release Summary" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="TLC" name="Tenant Level Cost" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="IC" name="Inventory Contracts" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="GLC" name="Go Live Data Copy" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="GLCD" name="Go Live Data Copy By Date" handleTabSelection={props.handleTabSelection} />}
				{!developerRole && <PageTile title="GS" name="Golive Stats" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="UUI" name="UnUsed Index" handleTabSelection={props.handleTabSelection} /> }
				{!developerRole && <PageTile title="MU" name="Mongo  Users" handleTabSelection={props.handleTabSelection} /> }
			</div>
		)
}

export default InternalApp