import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './assets/righttab.css';
import NewDatabaseInventory from './../databaseInventory/newDatabaseInventory'
import Environment from './../environment/Environment'
import LandingPage from './../landingPage/landingPage'
import Products from './../products/Products'
import Cost from './../databaseCost/Cost'
import Regions from './../regions/Regions'
import CloudProviders from './../cloudProvider/CloudProviders'
import AvailabilityZones from './../availabilityZone/AvailabilityZones'
import DatabaseEngines from './../engine/Engines'
import DatabaseEngineVersions from './../engineVersion/engineVersions'
import DatabaseActivities from './../activities/DatabaseActivities'
import Clusters from './../cluster/Clusters'
import NodeClass from './../nodeClass/nodeClass'
import SlowQuery from './../slowQuery/SlowQuery'
import AppSlowQuery  from './../slowQuery/NewSlowQuery'
import DatabaseReports from './../databaseReports/DatabaseReports'
import DatabaseCostSavingsReport from './../databaseCostSavingsReport/databaseCostSavingsReport'
import DatabseCostSummaryReport from './../databaseCostSummaryReport/databaseCostSummaryReport'
import AtlasCosts from './../atlasCosts/atlasCosts'
import CostReport from './../costReports/costReports'
import AuditLog from './../auditLog/auditLogs'
import TenantLevelCost from './../tenantLevelCost/tenantLevelCost'
import DatabaseTasks from './../databaseTasks/databaseTasks'
import DatbaseTasksAssignee from './../databaseTasks/databaseTasksAssignee'
import ReleaseDatabaseList from './../releaseDatabase/releaseDatabaseList'
import ReleaseJiraList from './../releaseDatabase/releaseJiraList'
import ReleaseSummary from './../releaseDatabase/releaseSummary'
import InventoryContracts from './../inventoryContracts/inventoryContracts'
import Users from './../users/users'
import GoLiveDataCopy from './../goLiveDataCopy/goLiveDataCopy'
import GoLiveDataCopyByDate from './../goLiveDataCopy/goLiveDataCopyByDate'
import UnUsedIndex from './../unUsedIndex/unUsedIndex'
import MongoUsers from './../mongoUsers/mongoUsers'
import GoLiveStats from './../goLiveDataCopy/goLiveStats'


function RightTab(props) {

  const [selectedTab, setSelectedTab] = useState('')


  useEffect(()=> {
    setSelectedTab(props.selectedTab)
  },[props.selectedTab])

  return (
    <div className="App-body-block-right-tab-container">
      {selectedTab === "DI" ? 
          <NewDatabaseInventory /> 
          : selectedTab === "E" ? 
            <Environment />
            : selectedTab === "PM" ? 
              <Products />
              : selectedTab === "DC" ?
                  <Cost />
                  : selectedTab === "CP" ?
                    <CloudProviders />
                    : selectedTab === "RS" ?
                      <Regions />
                      : selectedTab === "AZ" ?
                        <AvailabilityZones /> 
                        : selectedTab === "DE" ?
                          <DatabaseEngines />
                          : selectedTab === "CL" ?
                            <Clusters />
                            : selectedTab === "DA" ? 
                              <DatabaseActivities /> 
                              : selectedTab === "EV" ?
                                <DatabaseEngineVersions />
                                : selectedTab === "NC" ?
                                  <NodeClass /> 
                                  : selectedTab === "ER" ?
                                    <DatabaseReports />
                                    : selectedTab === "CR" ?
                                      <DatabaseCostSavingsReport />
                                      : selectedTab === "SQ" ?
                                        <SlowQuery />
                                        : selectedTab === "CS" ?
                                          <DatabseCostSummaryReport />
                                          : selectedTab === "AC" ?
                                            <AtlasCosts />
                                            : selectedTab === "COR" ?
                                              <CostReport />
                                              : selectedTab === "U" ?
                                                <Users />
                                                : selectedTab === "AL" ?
                                                  <AuditLog />
                                                  : selectedTab === "DT" ?
                                                    <DatabaseTasks />
                                                    : selectedTab === "DTA" ?
                                                      <DatbaseTasksAssignee />
                                                      : selectedTab === "RDB" ?
                                                        <ReleaseDatabaseList />
                                                        : selectedTab == "RJS" ? 
                                                          <ReleaseJiraList />
                                                          : selectedTab == "RSM" ?
                                                            <ReleaseSummary />
                                                            : selectedTab == "ASQ" ?
                                                              <AppSlowQuery />
                                                              : selectedTab == "TLC" ?
                                                                <TenantLevelCost /> 
                                                                : selectedTab == "IC" ?
                                                                  <InventoryContracts />
                                                                  : selectedTab == "GLC"?
                                                                    <GoLiveDataCopy />
                                                                    : selectedTab == "GLCD" ?
                                                                      <GoLiveDataCopyByDate />
                                                                      : selectedTab == "GS" ?
                                                                        <GoLiveStats />
                                                                        : selectedTab == "UUI" ?
                                                                          <UnUsedIndex />
                                                                          : selectedTab == "MU" ?
                                                                            <MongoUsers />
                                                                            : selectedTab === "Menu" ?
                                                                              <LandingPage handleTabSelection={props.handleTabSelection} />
              : 
              <LandingPage handleTabSelection={props.handleTabSelection} />
      }
    </div>
  );
}

RightTab.propTypes = {
    selectedTab: PropTypes.any
};

export default RightTab;
